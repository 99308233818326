import './../css/booking.css'
import './../../../admin/assets/src/css/rtl.css';
import { get, post } from './../../../admin/assets/src/utils/ajax';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';
import { Modal, Offcanvas ,Tab } from 'bootstrap';
import notificationToast from '../../../admin/assets/src/utils/notification-toast';
import 'add-to-calendar-button';
class Booking_Shotcode {

    shortCodeElement
    bookingTypeId
    bookingTypeAvailableDays
    bookingSpecificDay

    bookingModelInstance
    bookingFormElement

    bookingTimeSlot
    bookingDate

    bookingMaxPreBookingDays 
    bookingMaxPostBookingDays 

    bookingModelCloseBtn
    bookingModelSubmitBtn

    days = {
        "0": "sunday",
        "1": "monday",
        "2": "tuesday",
        "3": "wednesday",
        "4": "thursday",
        "5": "friday",
        "6": "saturday",
    }



    constructor(element) {
        this.shortCodeElement = element
        this.init()
        this.addEventListener()
    }
    get2DigitFmt(val) {
        return ('0' + val).slice(-2);
    }
    init() {

        this.bookingTypeId = this.shortCodeElement.querySelector('.wpb-booking-type-id')?.value
        if(this.bookingTypeId == undefined){
            this.bookingConfirm();
            return ;
        }
        this.bookingTypeAvailableDays = JSON.parse(this.shortCodeElement.querySelector('.wpb-booking-available-days').value)
        this.bookingSpecificDay = JSON.parse(this.shortCodeElement.querySelector('.wpb-booking-sepcific-date-days').value ?? {})
        
        this.bookingMaxPreBookingDays = this.shortCodeElement.querySelector('.wpb-booking-max-pre-booking-days').value;
        this.bookingMaxPostBookingDays = this.shortCodeElement.querySelector('.wpb-booking-max-post-booking-days').value;

        this.bookingModelCloseBtn = this.shortCodeElement.querySelector('.wpb-close-model-btn')
        this.bookingModelSubmitBtn = this.shortCodeElement.querySelector('.wpb-submit-model-btn')

        let TodayDate = new Date()

        let flatargs = {
            inline: true,
            allowInput: true, 
            formats: 'd-m-Y',
            onChange: (selectedDates, dateStr, instance) => {
                this.OnSelectDate(selectedDates, dateStr, instance)
            },
            onDayCreate: (dObj, dStr, fp, dayElem) => {
                if(!dayElem.classList.contains('flatpickr-disabled')){
                    if (this.days[dayElem.dateObj.getDay()] in this.bookingTypeAvailableDays && dayElem.dateObj <= new Date().fp_incr(this.bookingMaxPostBookingDays)) {
                        dayElem.classList.add('wpb-available');
                    }else{
                        dayElem.classList.add('flatpickr-disabled');
                    }
                }
                let formattedDate = dayElem.dateObj.getFullYear() + '-' + this.get2DigitFmt(dayElem.dateObj.getMonth() + 1) + '-' + this.get2DigitFmt(dayElem.dateObj.getDate());
                if (this.bookingSpecificDay[formattedDate]) {
                    dayElem.classList.add('wpb-available');
                    dayElem.classList.remove('flatpickr-disabled');
                }
                
            },
            onReady: ()=> {
                let year_input= jQuery(this.shortCodeElement).find('.numInput.cur-year')
                
                if(year_input.attr('max')==year_input.attr('min') ){
                    jQuery(this.shortCodeElement).find('.numInputWrapper .arrowUp,.numInputWrapper .arrowDown').hide()
                }
            }
        };
        if( this.bookingMaxPreBookingDays !="-1" ){
            flatargs ['minDate']= new Date().fp_incr(this.bookingMaxPreBookingDays)
        }
        if( this.bookingMaxPostBookingDays !="-1" ){
            flatargs ['maxDate']= new Date().fp_incr(this.bookingMaxPostBookingDays)
        }
        jQuery('.wpb-inline-flatpickr').flatpickr(flatargs);
        this.shortCodeElement.querySelector('.flatpickr-day.wpb-available').click()

        this.bookingModelInstance = new Modal(this.shortCodeElement.querySelector('.confirm-booking'), { 'backdrop': true, 'focus': true })
        this.bookingFormElement = this.shortCodeElement.querySelector('.wpb-shortcode-booking-form')
        

        this.validateField = this.validateField.bind(this);
        this.showError = this.showError.bind(this);
        this.hideError = this.hideError.bind(this);
        this.errorMessages = {
            wpb_user_name: {
                selector: "#booking_shortcode_full_name_error",
                blank: wp.i18n.__("Please enter Name", 'wpbookit'),
            },
            wpb_user_first_name: {
                selector: "#booking_shortcode_first_name_error",
                blank: wp.i18n.__("Please enter first name", 'wpbookit'),
            },
            wpb_user_last_name: {
                selector: "#booking_shortcode_last_name_error",
                blank: wp.i18n.__("Please enter last name", 'wpbookit'),
            },
            wpb_user_email: {
                selector: "#booking_shortcode_user_email_error",
                blank: wp.i18n.__("Please enter email", 'wpbookit'),
            },
            wpb_user_password: {
                selector: "#booking_shortcode_user_password_error",
                blank: wp.i18n.__("Please enter email", 'wpbookit'),
            },
            wpb_login_user_email: {
                selector: "#wp_login_booking_shortcode_user_email_error",
                blank: wp.i18n.__("Please enter email", 'wpbookit'),
            },
            wpb_login_user_password: {
                selector: "#wpb_login_booking_shortcode_user_password_error",
                blank: wp.i18n.__("Please enter email", 'wpbookit'),
            },
            wpb_payments_gateways: {
                selector: "wpb_payments_gateways",
                blank: wp.i18n.__("Please Select Payment Gateway", 'wpbookit'),
            }
        };

        this.registerTab= this.shortCodeElement.querySelector('#wpb-new-customer-tab')
        this.loginTab=this.shortCodeElement.querySelector('#wpb-already-customer-tab')
        this.shortCodeElement.querySelector('.confirm-booking').addEventListener('show.bs.modal',(e) => {
            jQuery(e.target).find('[name="wpb_payments_gateways"]').first().prop('checked', true).trigger('change')
        })
        this.shortCodeElement.querySelector('.confirm-booking').addEventListener('hide.bs.modal',(e) => {
            jQuery( this.bookingFormElement).trigger("reset");
        })
        
        this.getContainerWidth()
    }

    addEventListener() {
        jQuery(this.shortCodeElement.querySelector('.booking-slots-time')).on('click', '.btn', (e) => this.openModel(e))

        this.bookingFormElement?.addEventListener('sumbit', (e) => this.onFormSubmit(e))
        this.bookingModelSubmitBtn?.addEventListener('click', (e) => this.onFormSubmit(e))

        this.bookingModelCloseBtn?.addEventListener('click',()=>this.bookingModelInstance.hide())



        this.loginTab?.addEventListener('show.bs.tab',  (event)=> {
            this.shortCodeElement.querySelector('.wpb-user-booking-with').value = 'wpb-login';
        })
        this.registerTab?.addEventListener('show.bs.tab',  (event)=> {
            this.shortCodeElement.querySelector('.wpb-user-booking-with').value = 'wpb-register';
        })

        jQuery(window).resize(() => {
            this.getContainerWidth();
        });
    }
    
    validateField(value, errorSelector, blankErrorMessage, invalidErrorMessage) {
        if (!value) {
            this.showError(errorSelector, blankErrorMessage);
            return false;
        } else {
            this.hideError(errorSelector);
            return true;
        }
    }
    
    showError(elementId, errorMessage) {
        const errorElement = document.querySelector(elementId);
        if (errorElement) {
            errorElement.textContent = errorMessage;
            errorElement.style.display = 'block';
        }
    }
    
    hideError(elementId) {
        const errorElement = document.querySelector(elementId);
        if (errorElement) {
            errorElement.textContent = '';
            errorElement.style.display = 'none';
        }
    }    
    
    onFormSubmit(event) {
        event.preventDefault();
        const _this = this;

        const form = event.target.closest('form');
        const requiredFields = {};
        const fields = form.querySelectorAll('[required]');

        
        fields.forEach(field => {
            let tabContent = field.closest('.tab-pane.fade');
            if(tabContent !==null){
                if(tabContent.classList.contains('active')){
                    requiredFields[field.name] = field.value.trim();
                }
            }else{
                requiredFields[field.name] = field.value.trim();
            }

        });

        let isValid = [];

        jQuery.each(requiredFields, function (fieldName, fieldValue) {
            var error = _this.validateField(
                fieldValue,
                _this.errorMessages[fieldName].selector,
                _this.errorMessages[fieldName].blank,
                _this.errorMessages[fieldName].invalid
            )
            console.log(error,_this.errorMessages[fieldName]);
            isValid.push(error);
        });

        if (isValid.indexOf(false) !== -1) return false;
        
        let FormSubmit = Object.fromEntries(new FormData(event.target.closest('form')))

        FormSubmit = { ...FormSubmit, 'date': this.bookingDate, timeslot: this.bookingTimeSlot, bookingTypeId: this.bookingTypeId }

        
        this.bookingModelSubmitBtn.querySelector('.spinner').classList.remove('d-none');
        this.bookingModelSubmitBtn.disabled = true

        post('new_booking', FormSubmit).then((res) => {
            this.bookingModelSubmitBtn.querySelector('.spinner').classList.add('d-none');
            this.bookingModelSubmitBtn.disabled = false
            if(res.status == 'success'){
                // Clear the form fields
                //jQuery('#wpb-shortcode-booking-form')[0].reset();
                this.bookingModelInstance.hide();
                notificationToast[res.status](res.message, res.status.toUpperCase(), { autoClose: true });
                setTimeout(() => {
                    if(res.woo_payment_redirect !== undefined && res.woo_payment_redirect !== ''){
                        window.location.href =res.woo_payment_redirect;
                    }else if(res.payment_redirect !== undefined && res.payment_redirect !== ''){
                        window.location.href = res.payment_redirect
                    }else if(res.data.redirect_url !== undefined && res.data.redirect_url !== ''){
                        window.location.href = res.data.redirect_url
                    }
                    else{
                        var url = new URL(window.location.href);
                        url.searchParams.set('booking_confirmation', res.data.booking_id);
                        window.location.replace(url.toString());
                    }
                }, 2000);
                jQuery("body").trigger("update_checkout");
            }else{
                notificationToast[res.status](res.message, res.status.toUpperCase(), { autoClose: true });
            }
        })

    }
    OnSelectDate(Dates) {
        const [selectedDate] = Dates;
    
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const dateString = selectedDate.toLocaleDateString('en-US', options);
        const slotsContainer = this.shortCodeElement.querySelector(".booking-slots-time");


        slotsContainer.innerHTML=  `<div class="spinner-border " role="status">
  <span class="visually-hidden">Loading...</span>
</div>`
    
        this.bookingDate = dateString;
    
        get('get_booking_timeslot', {
            selected_date: dateString,
            bookingTypeId: this.bookingTypeId,
            _ajax_nonce: window.wpb_nounce,
        })
        .then(({ data }) => {
            if (data.trim() === "") {
                slotsContainer.innerHTML = wp.i18n.__("* No slots available for the selected date.",'wpbookit');
            } else {
                slotsContainer.innerHTML = data;
            }
        })
    }    
    openModel(e) {
        this.bookingTimeSlot = e.currentTarget.querySelector('.date-time-slot').dataset.time_slot;
        this.shortCodeElement.querySelector('.wpb-selected-timestap').innerHTML =   e.currentTarget.querySelector('.date-time-slot').dataset.date_formated
        this.bookingModelInstance.show()
    }

    bookingConfirm() {
        this.bookNewMeetingBtn = this.shortCodeElement.querySelector('.book_new_meeting');
        this.bookCancleBtn = this.shortCodeElement.querySelector('.cancel_meeting');
    
        this.bookNewMeetingBtn.addEventListener('click', () => {
            let currentUrl = window.location.href;
            let newUrl = currentUrl.split('?')[0];
            window.location.href = newUrl;
        });
        this.bookCancleBtn.addEventListener('click',(e)=>this.cancleBooking(e))

    }
    cancleBooking(e){
        if (window.confirm(window.wpbookit.confirm_delete_msg)) {
            
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('booking_confirmation');

            post('cancle_booking_appointment',{id}).then(res=>{
                let  {status,message}= res;
                notificationToast[status](message, status.toUpperCase(), { autoClose: true });
                if(status=='success'){
                    setTimeout(() => {
                    let currentUrl = window.location.href;
                    let newUrl = currentUrl.split('?')[0];
                    window.location.href = newUrl;
                    }, 2000);
                }
            })
        }
    }
    
    formatDate(date) {
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    getContainerWidth() {
        if (this.shortCodeElement.clientWidth < 851) {
            this.shortCodeElement.classList.add('content-width-small');
        } else {
            this.shortCodeElement.classList.remove('content-width-small');
        }
    }
}

jQuery(function () {
    new Booking_Shotcode(document.querySelector('.wpb-booking-shortcode'))
});
